<template>
  <form>
    <div class="row">
      <div class="col">
        <ul class="list-group w-100 mb-4">
          <li class="list-group-item d-flex justify-content-between">
            {{ getTrans("messages.auto_renew_insufficient_funds") }}
            <base-switch v-model="orderRenewNoFundsForNext"></base-switch>
          </li>
          <li class="list-group-item d-flex justify-content-between">
            {{ getTrans("messages.order_renewed") }}
            <base-switch v-model="orderRenew"></base-switch>
          </li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <base-button type="primary" @click="saveUser">{{
          getTrans("messages.save")
        }}</base-button>
      </div>
    </div>
  </form>
</template>
<script>
import {} from "element-plus";
import { mapGetters } from "vuex";
export default {
  components: {},
  data() {
    return {
      orderRenew: false,
      orderRenewNoFundsForNext: false,
    };
  },
  computed: {
    ...mapGetters("User", ["getUser"]),
  },
  methods: {
    saveUser() {
      this.$emit("save", {
        blocked_notifications: {
          orderRenew: this.orderRenew ? 0 : 1,
          orderRenewNoFundsForNext: this.orderRenewNoFundsForNext ? 0 : 1,
        },
      });
    },
  },
  mounted() {
    try {
      // true = block the notification
      this.orderRenew = !this.getUser.blocked_notifications.orderRenew;
      this.orderRenewNoFundsForNext = !this.getUser.blocked_notifications
        .orderRenewNoFundsForNext;
      // eslint-disable-next-line no-empty
    } catch (e) {}
  },
};
</script>
